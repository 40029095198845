import '../css/app.scss';

import $ from 'jquery';

import 'popper.js';
import 'bootstrap';
import 'lightbox2';
// import {AOS} from 'aos';
// import '~/node_modules/freeman.gdpr.cookiebanner/js/jquery.cookiebanner.js';

import { library, dom } from "@fortawesome/fontawesome-svg-core";
import { fal } from '@fortawesome/pro-light-svg-icons'
// import { faCheck } from "@fortawesome/free-solid-svg-icons/faCheck";


import jsCloser from'./public_module';

$(document).ready(function () {
    $('.dropdown-toggle').dropdown();
    $('.toast').toast('show');

    function getCookie(name) {
        var value = "; " + document.cookie;
        var parts = value.split("; " + name + "=");
        if (parts.length == 2) return parts.pop().split(";").shift();
    }

    // if ( getCookie('cb-enabled') !== "accepted" ) {
    //     $.cookieBar({
    //         message: 'Мы используем файлы cookie для сбора статистики',
    //         acceptButton: true,
    //         acceptText: 'Я понимаю',
    //         acceptFunction: null,
    //         declineButton: false,
    //         declineText: 'Disable Cookies',
    //         declineFunction: null,
    //         policyButton: true,
    //         policyText: 'Условия использования',
    //         policyURL: '/terms/',
    //         autoEnable: true,
    //         acceptOnContinue: false,
    //         acceptOnScroll: false,
    //         acceptAnyClick: false,
    //         expireDays: 365,
    //         renewOnVisit: false,
    //         forceShow: true,
    //         effect: 'slide',
    //         element: 'body',
    //         append: false,
    //         fixed: true,
    //         bottom: true,
    //         zindex: '9001',
    //     });
    // }

});

// library.add(faCheck);
library.add(fal);
dom.watch();

// AOS.init();
$('.custom-file-input').on('change', function(event) {
    var inputFile = event.currentTarget;
    $(inputFile).parent()
        .find('.custom-file-label')
        .html(inputFile.files[0].name);
});

console.log(jsCloser());

